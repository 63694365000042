export default Object.freeze({
  STATUSES: {
    TENANT_TEMPLATE: "tenant_template",
    USER_GENERATED_DEFAULT:"user_generated_default",
    CORPORATE_MODIFIED: "corporate_modified",
    PROPERTY_MODIFIED: "property_modified",
  },
  ICONS: {
    tenant_template: {
      name: "Tenant<sup>TM</sup> template",
      slug: "tenant_template",
      icon: "mdi-custom-tenant-logo",
      icon_color: "#38AFC6",
    },
    user_generated_default:{
        name: "User Generated Default",
        slug: "tenant_template",
        icon: "mdi-check-circle",
        icon_color: "#FB4C4C",
    },
    corporate_modified: {
      name: "Corporate Modified",
      slug: "corporate_modified",
      icon: "mdi-check-circle",
      icon_color: "#02AD0F",
    },
    property_modified: {
      name: "Property Modified",
      slug: "property_modified",
      icon: "mdi-check-circle",
      icon_color: "#FFD600",
    },
  },
});
